
































































import Vue from "vue";
import { Framework } from 'vuetify'

declare module 'vue/types/vue' {
  // this.$vuetify inside Vue components
  interface Vue {
    $vuetify: Framework;
  }
}
export default Vue.extend({
  name: "App",

  components: {
  },

  data: () => ({
    //
  }),
  methods: {
    navigate(whereto: string, opts: any): void {
      const route = this.$router.currentRoute.name;
      console.log(whereto, route)
      if(route) {
        if(route.toLowerCase() === "home") {
          this.$vuetify.goTo(whereto,opts)
        } else {
          this.$router.push('/'+whereto,opts)
        }
      }
    },

    goto(whereto: string): void {
      this.$router.push("/"+whereto)
    }
  }
});
