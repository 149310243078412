<template>
  <v-container class="px-10">
      <simple-back-button/>
      <h1 class="full-width text-center">Remaining Payment Details</h1>
      <v-alert
        color="red"
        outlined
        text
        type="error"
      >The payment of your remaining balance is due by <b>May 1st 2025</b>. Please make a payment ar your earliest convenience to guarantee your spot in the showcase.</v-alert>
      <div class="full-width text-center">
       <how-to-pay type-of-payment="remaining (full) payment" :check-amount="`${this.payment.check}`" :venmo-amount="`${this.payment.venmo}`"/>
      </div>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import HowToPay from "../HowToPay.vue"
import SimpleBackButton from "../../components/SimpleBackButton.vue"
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {HowToPay,SimpleBackButton},
  data() {
    return {
      athlete: [],
      payment: {}
    }
  },
  async mounted() {
    const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
    this.calculateFullPayment();
  },
  methods: {
    calculateFullPayment() {
      let deduct = 0;
      if(this.athlete.athleteRegisteredFor.toLowerCase().indexOf('High Performance Showcase ($500 | $510 Venmo)') > -1) {
        this.payment = {check: 500, venmo: 510};
      } else if(this.athlete.athleteRegisteredFor.toLowerCase().indexOf('Acro & Tumbling ($375 | $385 Venmo)') > -1) {
        this.payment = {check: 375, venmo: 385} ;
      } else if(this.athlete.athleteRegisteredFor.toLowerCase().indexOf('Bundle #1 [High Perf Showcase] + [Acro & Tumbling] - ($550 | $565 Venmo)') > -1) {
        this.payment = {check: 550, venmo: 565};
      } else if(this.athlete.athleteRegisteredFor.toLowerCase().indexOf('Bundle #2 [All Levels Camp] + [Acro & Tumbling] - ($425 | $435 Venmo)') > -1) {
        this.payment = {check: 425, venmo: 435};
      } else if(this.athlete.athleteRegisteredFor.toLowerCase().indexOf('All Levels - ($400 | $410 Venmo)') > -1) {
        this.payment = {check: 400, venmo: 410};
      }

      if (this.athlete.deposit) {
        deduct = 100
      }

      this.payment = {
        check: this.payment.check - deduct,
        venmo: this.payment.venmo - deduct
      }
      return this.payment
    }
  },
  computed: {

  },
}
</script>

<style lang="scss">


</style>