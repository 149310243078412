
















































































































































































  import Vue from "vue";
  //@ts-ignore
  import api from "@/api/covid-server-api";
import collegeTile from "../../components/college-tile.vue";
  export default Vue.extend({
    name: "Home",
    components: {
      collegeTile,
    },
    data: () => ({
      colleges: [
        { name: "Ohio State", logo: "/logos/ohioState.svg", confirmed: false}, 
        { name: "Ursinus", logo: "/logos/ursinus.png", confirmed: false}, 
        { name: "Washington", logo: "/logos/washington.png", confirmed: false}, 
        { name: "Central Michigan", logo: "/logos/centralMichigan.png", confirmed: false}, 
        { name: "UNH", logo: "/logos/unh.png", confirmed: true}, 
        { name: "Rutgers", logo: "/logos/rutgers.jpeg", confirmed: true}, 
        { name: "Cortland", logo: "/logos/cortland.png", confirmed: true}, 
        { name: "LSU", logo: "/logos/lsu.png", confirmed: false}, 
        { name: "Stanford", logo: "/logos/stanford.jpeg", confirmed: false}, 
        { name: "NC State", logo: "/logos/ncstate.png", confirmed: false}, 
        { name: "Oregon", logo: "/logos/oregon.png", confirmed: false}, 
        { name: "Oregon Acro & Tumbking", logo: "/logos/oregonAcro.jpeg", confirmed: false}, 
        { name: "Nebraska", logo: "/logos/nebraska.png", confirmed: true}, 
        { name: "Temple", logo: "/logos/temple.png", confirmed: true}, 
        { name: "Air Force Academy", logo: "/logos/airForceAcademy.png", confirmed: false}, 
        { name: "Yale", logo: "/logos/yale.png", confirmed: false}, 
        { name: "Minnesota", logo: "/logos/minnessota.png", confirmed: false}, 
        { name: "Iowa", logo: "/logos/iowa.png", confirmed: true}, 
        { name: "Southern Utah", logo: "/logos/southernUtah.png", confirmed: false}, 
        { name: "LIU", logo: "/logos/liu.png", confirmed: true}, 
        { name: "LIU A&T", logo: "/logos/liu.png", confirmed: false}, 
        { name: "Illinois University", logo: "/logos/illinois.jpeg", confirmed: true}, 
        { name: "Bowling Green", logo: "", confirmed: false}, 
        { name: "King A&T", logo: "", confirmed: false}, 
        { name: "East Stroudsburg University A&T", logo: "", confirmed: false}, 
        { name: "Belmont Abbey A&T", logo: "/logos/belmontAbbey.png", confirmed: false}, 
        { name: "Arkansas", logo: "/logos/arkansas.png", confirmed: false}, 
        { name: "NIU", logo: "/logos/niu.png", confirmed: false}, 
        { name: "Pitt", logo: "/logos/pitt.png", confirmed: false}, 
        { name: "Maryland", logo: "/logos/maryland.jpeg", confirmed: false}, 
        { name: "Brockport", logo: "/logos/brockport.png", confirmed: false}, 
        { name: "EMU", logo: "/logos/emu.png", confirmed: false}, 
        { name: "UPenn", logo: "/logos/upenn.jpeg", confirmed: false}, 
        { name: "Springfield", logo: "/logos/springfield.jpg", confirmed: true}, 
        { name: "Cal", logo: "/logos/cal.jpeg", confirmed: true}, 
        { name: "Baylor A&T", logo: "/logos/baylor.jpeg", confirmed: false}, 
        { name: "Penn State", logo: "/logos/pennstate.jpeg", confirmed: false}, 
        { name: "Gannon A&T", logo: "", confirmed: false}, 
        { name: "Ball State", logo: "/logos/ball-state.jpg", confirmed: true}, 
        { name: "SCSU", logo: "/logos/scsu.jpeg", confirmed: false}, 
        { name: "TWU", logo: "/logos/twu.png", confirmed: false}, 
        { name: "SEMO", logo: "/logos/semo.webp", confirmed: false}, 
        { name: "GW", logo: "/logos/gw.png", confirmed: true},
        { name: "Greenville", logo: "/logos/greenville.png", confirmed: false},
        { name: "Arizona", logo: "/logos/arizona.jpeg", confirmed: true},
        { name: "Mizzou", logo: "/logos/mizzou.jpeg", confirmed: false},
        { name: "Cornell", logo: "/logos/cornell.png", confirmed: false},
        { name: "Centenary", logo: "/logos/centenary.png", confirmed: false},
        { name: "West Chester", logo: "/logos/westchester.jpeg", confirmed: false},
        { name: "Concordia University A&T", logo: "/logos/concordia.jpeg", confirmed: false},
        { name: "Michigan", logo: "/logos/michigan.svg", confirmed: true},
        { name: "Bridgeport", logo: "/logos/bridgeport.png", confirmed: true},
        { name: "Alabama", logo: "/logos/alabama.png", confirmed: false},
        { name: "Trine University A&T", logo: "/logos/trine.jpeg", confirmed: false},
        { name: "Lindenwood", logo: "/logos/lindenwood.jpeg", confirmed: false},
        { name: "Denver", logo: "/logos/denver.jpeg", confirmed: false},
        { name: "Brown", logo: "/logos/brown.jpeg", confirmed: true},
        { name: "Ganon A&T", logo: "/logos/ganon.jpeg", confirmed: false},
        { name: "Willian & Mary", logo: "/logos/williamAndMart.png", confirmed: false},
        { name: "Georgia", logo: "/logos/georgia.png", confirmed: false},
        { name: "Fisk University", logo: "/logos/fisk.jpeg", confirmed: true},
        { name: "Utica", logo: "/logos/utica.png", confirmed: false},
        { name: "Fleet A&T", logo: "/logos/fleet.png", confirmed: false},
        { name: "Wingate A&T", logo: "/logos/wingate.jpeg", confirmed: false},
      ]
    }),
    computed: {
      collegesSorted(){
        const sorted = [...this.colleges];
        return sorted.sort((a, b) => a.name.localeCompare(b.name)).filter((item)=>item.confirmed);
      }
    },
    methods: {
    },
  });
